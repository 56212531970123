@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1500px) {
  .homepage_image{
    display: none;
  } 
}


@media screen and (max-width: 740px) {
  .homepage_phone{
    display: none;
  }  
}

@keyframes rotation {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  25% {
    transform: rotate(150deg) translateY(0px);
  }
  50% {
    transform: rotate(360deg) translateY(-10px);
  }
  75% {
    transform: translateY(-14px);
  }
  100% {
    transform: translateY(0) translateY(0px);;
  }
}

@keyframes rotation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0)
  }
}

.loader{
  animation: rotation 1s infinite linear;
}

.loader1{
  animation: zoom 1s infinite linear;
}

.Grid .Likes{
  cursor: pointer;
}
.Grid .Likes .goUp{
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  transition: 0.1s ease-in-out;
}
.Grid .Likes .waitDown{
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}
.Grid .Likes .goDown{
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: 0.1s ease-in-out;
}
.Grid .Likes .waitUp{
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, -20px, 0);
}
.Grid .Likes .initial{
  display: inline-flex;
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  transition: 0.1s ease-in-out;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: #dadada;
}

.MuiMenuItem-root.Mui-selected {
  background: yellow;
}
.MuiTablePagination-select[aria-expanded="true"] {
  border: 2px solid green;
}

@media (min-width: 1024px) {
  .custom-max-width {
    max-width: var(--max-width);
  }
}